import Chart from 'stimulus-chartjs'

function formatMoney(value) {
  var money = '$' + value;
  if (parseInt(value) >= 1000) {
    money = '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return money.split('.')[0]
}

export default class extends Chart {
  static values = {
    title: String
  }

  get defaultOptions() {
    return {
      minBarLength: 2,
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          ticks: {
            callback: function(value) { return formatMoney(value) }
          },
        }
      },
      plugins: {
        title: {
          display: true,
          text: this.titleValue
        },
        tooltip: {
          callbacks: {
            label: function(tooltipItem) { return formatMoney(tooltipItem.raw) }
          },
        },
      }
    }
  }
}

