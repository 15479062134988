// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");


import '../stylesheets/application';
import '../controllers/index.js';
import './bootstrap_custom.js';
import './youtube.js';


$(document).on("turbo:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  $('[data-toggle="tooltip"]').on('click', function () {
    $(this).tooltip('hide')
  })
});
