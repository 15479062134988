import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];

  launchDetailModal(event) {
    let videoModalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "video-modal"
    );

    videoModalController.setModalContent(event.currentTarget.dataset);
    videoModalController.open();
  }
}
