import { Controller } from "stimulus";

export default class extends Controller {
  reset() {
    this.element.reset();
  }

  clear() {
    if (this.element.querySelector("#form-errors") !== null) {
      this.element.querySelector("#form-errors").remove();
    }
  }
}