import CharacterCounter from 'stimulus-character-counter'

export default class extends CharacterCounter {
  static targets = ["word"];

  connect() {
    super.connect()
    this.updateWordCount()
  }

  updateWordCount() {
    this.wordTarget.innerHTML = this.inputTarget.value.trim().split(/\s+/).length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    this.counterTarget.innerHTML = this.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
}