import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ['title', 'iframe', 'description', 'ytlink'];

    setModalContent(data) {
      this.titleTarget.innerHTML = data.title;
      this.iframeTarget.src = 'https://www.youtube.com/embed/' + data.ytid + '?rel=0&autoplay=1';
      this.descriptionTarget.innerHTML = data.description;
      this.ytlinkTarget.href = 'https://www.youtube.com/watch?v=' + data.ytid
    }

    open() {
      document.body.classList.add("modal-open");
      this.element.setAttribute("style", "display: block;");
      this.element.classList.add("show");
      document.body.innerHTML += '<div id="modal-backdrop" class="modal-backdrop fade show"></div>';
    }

    close(event) {
      if (event && event.target.classList.contains("can-close") && this.element.classList.contains("show")) {
        document.body.classList.remove("modal-open");
        this.element.removeAttribute("style");
        this.element.classList.remove("show");
        document.getElementsByClassName("modal-backdrop")[0].remove();
        // stop playing the YT video
        this.iframeTarget.src = ""
      }
    }

}
